export function isContent(definition) {
    return !isLayout(definition);
}
export function isLayout(definition) {
    return (definition.component === 'ButtonOverlay' ||
        definition.component === 'Stacked' ||
        definition.component === 'Style' ||
        definition.component === 'Carousel' ||
        definition.component === 'Container' ||
        definition.component === 'Modal' ||
        definition.component === 'PeacockBackground' ||
        definition.component === 'RtcRoomContainer');
}
