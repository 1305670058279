"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeRequest = exports.ChallengeTypes = void 0;
const typebox_1 = require("@sinclair/typebox");
const ChallengeNewPassword = typebox_1.Type.Object({
    name: typebox_1.Type.Literal('NewPassword'),
    parameters: typebox_1.Type.Object({
        email: typebox_1.Type.String({ title: 'Email', format: 'email' }),
        newPassword: typebox_1.Type.String({ title: 'New Password' }),
    }, { title: 'Choose new password' }),
    session: typebox_1.Type.Optional(typebox_1.Type.String({ title: 'Authentication Session' })),
});
const ChallengeSmsMfa = typebox_1.Type.Object({
    name: typebox_1.Type.Literal('SmsMfa'),
    parameters: typebox_1.Type.Object({
        code: typebox_1.Type.String({
            title: 'Code',
            description: 'Code received on your mobile device.',
            format: 'regex',
            pattern: '[0-9]{6,8}',
        }),
        email: typebox_1.Type.String({ title: 'Email', format: 'email' }),
    }, { title: '2FA' }),
    session: typebox_1.Type.Optional(typebox_1.Type.String({ title: 'Authentication Session' })),
});
const ChallengeOtpMfa = typebox_1.Type.Object({
    name: typebox_1.Type.Literal('OtpMfa'),
    parameters: typebox_1.Type.Object({
        code: typebox_1.Type.String({
            title: 'Code',
            description: 'Code from your authenticator software.',
            format: 'regex',
            pattern: '[0-9]{6,8}',
        }),
        email: typebox_1.Type.String({ title: 'Email', format: 'email' }),
    }, { title: '2FA' }),
    session: typebox_1.Type.Optional(typebox_1.Type.String({ title: 'Authentication Session' })),
});
const ChallengeMfaSetup = typebox_1.Type.Object({
    name: typebox_1.Type.Literal('MfaSetup'),
    parameters: typebox_1.Type.Object({
        email: typebox_1.Type.String({ title: 'Email', format: 'email' }),
    }, { title: 'Time to setup 2FA' }),
    session: typebox_1.Type.String({ title: 'Authentication Session' }),
});
exports.ChallengeTypes = {
    MfaSetup: ChallengeMfaSetup,
    NewPassword: ChallengeNewPassword,
    OtpMfa: ChallengeOtpMfa,
    SmsMfa: ChallengeSmsMfa,
};
exports.ChallengeRequest = typebox_1.Type.Union([
    ChallengeMfaSetup,
    ChallengeNewPassword,
    ChallengeOtpMfa,
    ChallengeSmsMfa,
]);
