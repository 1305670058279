"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GqlRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
const operationName = typebox_1.Type.String({
    title: 'Name of the GQL operation to execute',
    description: 'Used to determine which operation to execute',
});
const variables = typebox_1.Type.Union([
    typebox_1.Type.String(),
    typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Any()),
]);
exports.GqlRequest = typebox_1.Type.Object({
    operationName: typebox_1.Type.Optional(operationName),
    query: typebox_1.Type.String({
        title: 'GQL Query Document',
        description: 'The GraphQL document to execute',
    }),
    variables: typebox_1.Type.Optional(variables),
});
