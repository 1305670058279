"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetLanguageCode = void 0;
const typebox_1 = require("@sinclair/typebox");
// Shadowing enum from MediaConvert sdk, rather than
// adding additional dependencies here.
const LanguageCodes = [
    'AAR',
    'ABK',
    'AFR',
    'AKA',
    'AMH',
    'ARA',
    'ARG',
    'ASM',
    'AVA',
    'AVE',
    'AYM',
    'AZE',
    'BAK',
    'BAM',
    'BEL',
    'BEN',
    'BIH',
    'BIS',
    'BOD',
    'BOS',
    'BRE',
    'BUL',
    'CAT',
    'CES',
    'CHA',
    'CHE',
    'CHU',
    'CHV',
    'COR',
    'COS',
    'CRE',
    'CYM',
    'DAN',
    'DEU',
    'DIV',
    'DZO',
    'ELL',
    'ENG',
    'ENM',
    'EPO',
    'EST',
    'EUS',
    'EWE',
    'FAO',
    'FAS',
    'FIJ',
    'FIN',
    'FRA',
    'FRM',
    'FRY',
    'FUL',
    'GER',
    'GLA',
    'GLE',
    'GLG',
    'GLV',
    'GRN',
    'GUJ',
    'HAT',
    'HAU',
    'HEB',
    'HER',
    'HIN',
    'HMO',
    'HRV',
    'HUN',
    'HYE',
    'IBO',
    'IDO',
    'III',
    'IKU',
    'ILE',
    'INA',
    'IND',
    'IPK',
    'ISL',
    'ITA',
    'JAV',
    'JPN',
    'KAL',
    'KAN',
    'KAS',
    'KAT',
    'KAU',
    'KAZ',
    'KHM',
    'KIK',
    'KIN',
    'KIR',
    'KOM',
    'KON',
    'KOR',
    'KUA',
    'KUR',
    'LAO',
    'LAT',
    'LAV',
    'LIM',
    'LIN',
    'LIT',
    'LTZ',
    'LUB',
    'LUG',
    'MAH',
    'MAL',
    'MAR',
    'MKD',
    'MLG',
    'MLT',
    'MON',
    'MRI',
    'MSA',
    'MYA',
    'NAU',
    'NAV',
    'NBL',
    'NDE',
    'NDO',
    'NEP',
    'NLD',
    'NNO',
    'NOB',
    'NOR',
    'NYA',
    'OCI',
    'OJI',
    'ORI',
    'ORJ',
    'ORM',
    'OSS',
    'PAN',
    'PLI',
    'POL',
    'POR',
    'PUS',
    'QAA',
    'QPC',
    'QUE',
    'ROH',
    'RON',
    'RUN',
    'RUS',
    'SAG',
    'SAN',
    'SIN',
    'SLK',
    'SLV',
    'SME',
    'SMO',
    'SNA',
    'SND',
    'SOM',
    'SOT',
    'SPA',
    'SQI',
    'SRB',
    'SRD',
    'SRP',
    'SSW',
    'SUN',
    'SWA',
    'SWE',
    'TAH',
    'TAM',
    'TAT',
    'TEL',
    'TGK',
    'TGL',
    'THA',
    'TIR',
    'TNG',
    'TON',
    'TSN',
    'TSO',
    'TUK',
    'TUR',
    'TWI',
    'UIG',
    'UKR',
    'URD',
    'UZB',
    'VEN',
    'VIE',
    'VOL',
    'WLN',
    'WOL',
    'XHO',
    'YID',
    'YOR',
    'ZHA',
    'ZHO',
    'ZUL',
];
exports.AssetLanguageCode = typebox_1.Type.Union(LanguageCodes.map((code) => typebox_1.Type.Literal(code)));
