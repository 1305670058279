"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsPayload = exports.AnalyticsInstruction = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.AnalyticsInstruction = typebox_1.Type.Object({
    /** Timestamp from browser when the instruction was broadcast */
    localTimestamp: typebox_1.Type.String({
        description: 'Timestamp from browser when the instruction was broadcast ',
        format: 'date-time',
    }),
    /** Instruction details */
    meta: typebox_1.Type.Unknown({ description: 'Instruction details' }),
    /** Identifiers for the module or system which broadcast the instruction */
    source: model_1.InstructionSource,
    /** Instruction type */
    type: typebox_1.Type.String({ description: 'Instruction type' }),
});
exports.AnalyticsPayload = typebox_1.Type.Object({
    batchTimestamp: typebox_1.Type.String({
        description: 'Timestamp from browser when the batch payload was created',
        format: 'date-time',
    }),
    domainName: typebox_1.Type.String({
        description: 'Domain on which the `showId` was viewed ',
    }),
    token: typebox_1.Type.String({
        description: 'Unique identifier representing guest',
    }),
    instructions: typebox_1.Type.Array(exports.AnalyticsInstruction, {
        description: 'Instruction payload contents',
    }),
    showId: typebox_1.Type.String({
        description: 'Unique identifier for the show',
        format: 'uuid',
    }),
});
