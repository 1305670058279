"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nullable = exports.Maybe = void 0;
const typebox_1 = require("@sinclair/typebox");
/** Modifies an object property to be optional and nullable */
function Maybe(item, options) {
    return typebox_1.Type.Optional(typebox_1.Type.Union([item, typebox_1.Type.Null(options)]));
}
exports.Maybe = Maybe;
/** Modifies an object property to be nullable */
function Nullable(item, options) {
    return typebox_1.Type.Union([item, typebox_1.Type.Null(options)]);
}
exports.Nullable = Nullable;
