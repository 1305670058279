import type {SiteDetailsByDomainQuery} from '@backstage/attendee-ui-types';
import {type MutableRefObject, useEffect, useMemo, useRef} from 'react';
import {config} from '../../config';
import {isLivePreviewHost, isPreviewDomain} from './domain-helpers';

type Site = SiteDetailsByDomainQuery['site'];

const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

/*
 * Parses Url to find valid show associated with valid site
 */
export function useShowId(shows: Site['shows']): string | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const {host, hostname, pathname, protocol} = location ?? {};
  const pathShowId = useMemo<string | undefined>(() => {
    const loc =
      host && hostname && pathname && protocol
        ? {host, hostname, pathname, protocol}
        : undefined;
    if (!loc) {
      return undefined;
    } else if (isLivePreviewHost(config, loc)) {
      // `isLivePreviewHost` is not mutually exclusive with `isPreviewDomain`
      // so it needs to be checked first.
      const [, showId] = loc.pathname.split('/');
      return showId;
    } else if (isPreviewDomain(config, loc)) {
      const [, , showId] = loc.pathname.split('/');
      return showId;
    } else {
      const [, showId] = loc.pathname.split('/');
      return showId;
    }
  }, [host, hostname, pathname, protocol]);
  const showId = useMemo(
    () =>
      extractShowId(
        shows.map((show) => show.id),
        pathShowId
      ),
    [shows, pathShowId]
  );

  return showId;
}

/**
 * Splits given `flatShowIds` and searches for `pathShowId` in it returning a
 * `Ref` containing the current, validated, `showId`.
 * @param flatShowIds comma-separated show ids, using comma separated string so
 * updates are comparable with `Object.is` to short circuit some updates.
 * @param pathShowId showId candidate parsed out from the pathname of the `URL`
 * @returns a stable `Ref` object holding the show id validated against
 * `flatShowIds`
 */
export function useShowIdRef(
  flatShowIds: string,
  pathShowId?: string
): MutableRefObject<string | undefined> {
  const showIds = useMemo(
    () => flatShowIds.split(',').filter((s) => s !== ''),
    [flatShowIds]
  );
  const showIdRef = useRef<string | undefined>(
    extractShowId(showIds, pathShowId)
  );
  useEffect(() => {
    showIdRef.current = extractShowId(showIds, pathShowId);
  }, [showIds, pathShowId]);
  return showIdRef;
}

function extractShowId(
  siteShowIds: string[],
  pathShowId?: string
): string | undefined {
  if (
    pathShowId &&
    uuidRegex.test(pathShowId) &&
    (siteShowIds.includes(pathShowId) || siteShowIds.length === 0)
  ) {
    return pathShowId;
  } else if (siteShowIds.length > 0) {
    return siteShowIds[0];
  } else {
    return undefined;
  }
}
