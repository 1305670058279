"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
const LoginPasswordRequest = typebox_1.Type.Object({
    email: typebox_1.Type.String({
        title: 'Email Address',
        format: 'email',
        description: 'The email address to use in the credentials.',
    }),
    password: typebox_1.Type.String({
        title: 'Password',
        description: 'The password to use in the credentials.',
    }),
    deviceKey: typebox_1.Type.Optional(typebox_1.Type.String({
        description: 'A key indicating the device from which credentials originated.',
    })),
});
const LoginSrpaRequest = typebox_1.Type.Object({
    email: typebox_1.Type.String({
        title: 'Email',
        format: 'email',
        description: 'The email address to use in the credentials.',
    }),
    srpA: typebox_1.Type.String({
        title: 'SRP A',
        description: 'The secure remote password associated with `email` to use in the credentials.',
    }),
    deviceKey: typebox_1.Type.Optional(typebox_1.Type.String({
        description: 'A key indicating the device from which credentials originated.',
    })),
});
exports.LoginRequest = typebox_1.Type.Union([
    LoginPasswordRequest,
    LoginSrpaRequest,
]);
