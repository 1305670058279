"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowGuestAddResponseSchema = exports.ShowGuestAddResponse = exports.ShowGuestAddRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
const errors_1 = require("../../../errors");
const http_status_codes_1 = require("../../../http-status-codes");
const rest_errors_1 = require("../../rest-errors");
const GuestId = typebox_1.Type.String({
    description: 'id for the created guest',
    format: 'uuid',
    title: 'Guest Id',
});
const GuestAccessCode = typebox_1.Type.String({
    description: 'The access code to assigned to the guest. If a value is not provided, the system will automatically generate an alphanumeric six character code and return it in the response.',
    examples: ['abc123'],
    maxLength: 25,
    minLength: 6,
    pattern: '^[a-zA-Z0-9]+$',
    title: 'Guest Access Code',
});
/**
 * Describes request body for REST API endpoint to add a guest (attendee &
 * access code) to a Show.
 */
exports.ShowGuestAddRequest = typebox_1.Type.Object({
    name: typebox_1.Type.String({
        description: 'The name of the guest',
        minLength: 1,
    }),
    email: typebox_1.Type.String({
        description: 'The email address of the guest',
        format: 'email',
    }),
    accessCode: typebox_1.Type.Optional(GuestAccessCode),
    publish: typebox_1.Type.Optional(typebox_1.Type.Boolean({
        default: false,
        description: 'Whether to publish the new guest immediately to the live site. If false or not provided, the site will need to be published before the guest is able to access the site.',
    })),
}, {
    description: 'Adds an guest to an existing show. If a guest with the provided email already exists the name (and accessCode if provided) will be updated',
});
const AddGuestSuccessBody = typebox_1.Type.Union([
    typebox_1.Type.Object({
        id: GuestId,
    }),
    typebox_1.Type.Object({
        accessCode: GuestAccessCode,
        id: GuestId,
    }),
]);
/**
 * Describes valid response HTTP status codes paired with response body for REST
 * API endpoint to add a guest (attendee & access code) to a Show.
 */
exports.ShowGuestAddResponse = typebox_1.Type.Union([
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.Ok),
        body: AddGuestSuccessBody,
    }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.NotFound),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('ShowNotExist', {
            description: 'The show was not found',
        })),
    }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.UnprocessableEntity),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('AccessCodeExisted', {
            description: 'The provided accessCode is already assigned to another guest',
        })),
    }),
    rest_errors_1.RestErrorResponse,
]);
/**
 * OpenAPI style specification of response code mapped to response schema.
 */
exports.ShowGuestAddResponseSchema = exports.ShowGuestAddResponse.anyOf
    .flatMap((response) => ('anyOf' in response ? response.anyOf : response))
    .reduce((schema, response) => {
    schema[response.properties.statusCode.const] = response.properties.body;
    return schema;
}, {});
