"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimestampResponse = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.TimestampResponse = typebox_1.Type.Object({
    iso: typebox_1.Type.String({
        title: 'ISO Time',
        description: '"Current" time as an ISO string.',
        format: 'date-time',
    }),
    utc: typebox_1.Type.String({
        title: 'UTC Time',
        description: '"Current" time as a UTC formatted string.',
    }),
});
