"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventBusMessage = exports.SiteVersionSnapshotCompleteMessage = exports.SiteVersionReadyMessage = exports.SiteVersionMessage = exports.SiteVersionDeployedMessage = exports.SiteVersionDeployMessage = exports.SiteVersionCreatedMessage = exports.SitePublishServiceMessage = exports.ShowPublishedMessage = exports.ShowPublishMessage = exports.ShowPublishServiceMessage = exports.ShowGuestAddedMessage = exports.EmailMessageEvent = exports.AssetManagementEvent = void 0;
const typebox_1 = require("@sinclair/typebox");
const asset_management_messages_1 = require("./asset-management-messages");
const show_guest_1 = require("./show-guest");
const show_publish_service_1 = require("./show-publish-service");
const site_publish_service_1 = require("./site-publish-service");
const email_1 = require("./email");
var asset_management_messages_2 = require("./asset-management-messages");
Object.defineProperty(exports, "AssetManagementEvent", { enumerable: true, get: function () { return asset_management_messages_2.AssetManagementEvent; } });
var email_2 = require("./email");
Object.defineProperty(exports, "EmailMessageEvent", { enumerable: true, get: function () { return email_2.EmailMessageEvent; } });
var show_guest_2 = require("./show-guest");
Object.defineProperty(exports, "ShowGuestAddedMessage", { enumerable: true, get: function () { return show_guest_2.ShowGuestAddedMessage; } });
var show_publish_service_2 = require("./show-publish-service");
Object.defineProperty(exports, "ShowPublishServiceMessage", { enumerable: true, get: function () { return show_publish_service_2.ShowPublishServiceMessage; } });
var show_publish_1 = require("./show-publish");
Object.defineProperty(exports, "ShowPublishMessage", { enumerable: true, get: function () { return show_publish_1.ShowPublishMessage; } });
Object.defineProperty(exports, "ShowPublishedMessage", { enumerable: true, get: function () { return show_publish_1.ShowPublishedMessage; } });
var site_publish_service_2 = require("./site-publish-service");
Object.defineProperty(exports, "SitePublishServiceMessage", { enumerable: true, get: function () { return site_publish_service_2.SitePublishServiceMessage; } });
var site_version_1 = require("./site-version");
Object.defineProperty(exports, "SiteVersionCreatedMessage", { enumerable: true, get: function () { return site_version_1.SiteVersionCreatedMessage; } });
Object.defineProperty(exports, "SiteVersionDeployMessage", { enumerable: true, get: function () { return site_version_1.SiteVersionDeployMessage; } });
Object.defineProperty(exports, "SiteVersionDeployedMessage", { enumerable: true, get: function () { return site_version_1.SiteVersionDeployedMessage; } });
Object.defineProperty(exports, "SiteVersionMessage", { enumerable: true, get: function () { return site_version_1.SiteVersionMessage; } });
Object.defineProperty(exports, "SiteVersionReadyMessage", { enumerable: true, get: function () { return site_version_1.SiteVersionReadyMessage; } });
Object.defineProperty(exports, "SiteVersionSnapshotCompleteMessage", { enumerable: true, get: function () { return site_version_1.SiteVersionSnapshotCompleteMessage; } });
/**
 * Union of any messages produced to or consumed from the Event Bus by
 * `@backstage/messaging-api`.
 */
exports.EventBusMessage = typebox_1.Type.Union([
    ...asset_management_messages_1.AssetManagementEvent.anyOf,
    ...show_guest_1.ShowGuestMessage.anyOf,
    ...show_publish_service_1.ShowPublishServiceMessage.anyOf,
    ...site_publish_service_1.SitePublishServiceMessage.anyOf,
    email_1.EmailMessageEvent,
]);
