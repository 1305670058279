"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticateResponse = exports.AuthenticateResult = exports.AuthenticatedSession = exports.AuthToken = exports.SimpleToken = exports.BaseSession = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const Challenge = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    parameters: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.String()),
    session: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const AuthError = typebox_1.Type.Object({
    name: typebox_1.Type.Union([
        typebox_1.Type.Literal('UserNotFoundException'),
        typebox_1.Type.Literal('NotAuthorizedException'),
        typebox_1.Type.Literal('InvalidPasswordException'),
    ]),
    message: typebox_1.Type.String(),
    stack: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const UnknownError = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    message: typebox_1.Type.String(),
    stack: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.BaseSession = typebox_1.Type.Object({
    sessionId: typebox_1.Type.String({
        examples: ['j8uL7b4EQHpIsA-EmHpVb-hmGbTCVvzIqg5jae4LdFM'],
    }),
    serviceId: typebox_1.Type.String({
        format: 'uuid',
    }),
    serviceType: model_1.ServiceType,
});
const OAuthToken = typebox_1.Type.Object({
    idToken: typebox_1.Type.String({
        description: 'Token related to the identity of the credentials.',
    }),
    accessToken: typebox_1.Type.String({
        description: 'Token related to the access the credentials have been granted.',
    }),
    refreshToken: typebox_1.Type.String({
        description: 'Token used to refresh access of credentials.',
    }),
    duration: typebox_1.Type.Integer({
        description: 'Duration in seconds before access token expires.',
        examples: [3600],
    }),
});
exports.SimpleToken = typebox_1.Type.Object({
    token: typebox_1.Type.String({
        description: 'Token indicating identity of the credentials and the access the credentials have been granted.',
    }),
});
exports.AuthToken = typebox_1.Type.Union([OAuthToken, exports.SimpleToken]);
exports.AuthenticatedSession = typebox_1.Type.Union([
    typebox_1.Type.Intersect([exports.BaseSession, OAuthToken]),
    typebox_1.Type.Intersect([exports.BaseSession, exports.SimpleToken]),
]);
exports.AuthenticateResult = typebox_1.Type.Union([
    Challenge,
    OAuthToken,
    exports.SimpleToken,
]);
exports.AuthenticateResponse = typebox_1.Type.Union([
    AuthError,
    Challenge,
    exports.AuthenticatedSession,
]);
