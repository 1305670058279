"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestErrorResponse = void 0;
const typebox_1 = require("@sinclair/typebox");
const errors_1 = require("../errors");
const http_status_codes_1 = require("../http-status-codes");
exports.RestErrorResponse = typebox_1.Type.Union([
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.BadRequest),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Union([
            typebox_1.Type.Literal('InvalidApiVersion', {
                description: 'The API version requested was invalid',
            }),
            typebox_1.Type.Literal('MalformedRequest', {
                description: 'The request did not validate against the specification',
            }),
        ])),
    }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.Forbidden),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('NotAuthorized', {
            description: 'The provided authorization token is not permitted to make changes to the resource',
        })),
    }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.TooManyRequests),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('RateLimitExceeded', {
            description: 'The provided authorization token has exceeded permitted number of requests in rate limit period',
        })),
        rateLimit: typebox_1.Type.Number({
            title: 'Rate Limit',
            description: 'Maximum number of requests in the time period',
        }),
        requestCount: typebox_1.Type.Number({
            title: 'Request Count',
            description: 'Number of requests counted in the time period',
        }),
        requestsRemaining: typebox_1.Type.Number({
            title: 'Requests Remaining Count',
            description: 'Number of requests left before exceeding the threshold',
        }),
    }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.InternalServerError),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('Internal', {
            description: 'An internal server error occurred',
        })),
    }),
]);
