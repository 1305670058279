"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecoverResponse = exports.RecoverRequest = exports.RecoverConfirmRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.RecoverConfirmRequest = typebox_1.Type.Object({
    email: typebox_1.Type.String({
        title: 'Email Address',
        format: 'email',
        description: 'The email address attempting to recover access.',
    }),
    password: typebox_1.Type.String({
        title: 'Enter New Password',
        description: 'The new password to use in the credentials.',
    }),
    code: typebox_1.Type.String({
        title: 'Confirmation Code',
        description: 'Code received via email or SMS.',
        format: 'regex',
        pattern: '[0-9]{6,8}',
    }),
    deviceKey: typebox_1.Type.Optional(typebox_1.Type.String({
        description: 'A key indicating the device from which credentials originated.',
    })),
});
exports.RecoverRequest = typebox_1.Type.Object({
    email: typebox_1.Type.String({
        title: 'Email Address',
        format: 'email',
        description: 'The email address attempting to recover access.',
    }),
    deviceKey: typebox_1.Type.Optional(typebox_1.Type.String({
        description: 'A key indicating the device from which credentials originated.',
    })),
});
exports.RecoverResponse = typebox_1.Type.Object({
    attributeName: typebox_1.Type.Optional(typebox_1.Type.String()),
    deliveryMedium: typebox_1.Type.Union([typebox_1.Type.Literal('EMAIL'), typebox_1.Type.Literal('SMS')]),
});
