import Cookie from 'js-cookie';
import {useEffect, useMemo} from 'react';
import {config} from '../../config';
import {isLivePreviewHost, isPreviewDomain} from './domain-helpers';

/**
 * Parses `Location` to find domain name.
 * @returns the value to use as the domain of the site.
 */
export function useDomainName(): LocationDetails | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const result = useMemo<LocationDetails | undefined>(() => {
    if (!location) {
      return undefined;
    } else if (isLivePreviewHost(config, location)) {
      const [, showId] = location.pathname.split('/');
      const queryParams = new URLSearchParams(location.search);
      const domainName =
        queryParams.get('host') ?? Cookie.get('host') ?? location.hostname;
      return {isPreview: false, domainName, showId};
    } else if (isPreviewDomain(config, location)) {
      const [, domainName, showId] = location.pathname.split('/');
      return {isPreview: true, domainName, showId};
    } else {
      const [, showId] = location.pathname.split('/');
      return {isPreview: false, domainName: location.hostname, showId};
    }
  }, [location]);
  useEffect(() => {
    if (result?.domainName && isLivePreviewHost(config, location)) {
      Cookie.set('host', result?.domainName, {
        sameSite: 'strict',
        secure: true,
      });
    }
  }, [location, result?.domainName]);
  return result;
}

export interface LocationDetails {
  isPreview: boolean;
  domainName: string;
  showId: string;
}
